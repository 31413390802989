<template>
  <b-card class="success-register">
    <b-row
      class="text-center"
    >
      <b-col
        cols="12"
        class="text-center"
      >
        <feather-icon
          variant="success"
          icon="CheckCircleIcon"
          size="130"
          color="#94d56c"
        />
      </b-col>
      <b-col
        cols="12"
        class="mt-3"
      >
        <h2
          style="color:#94d56c"
        >
          {{ $t('activation_success.header') }}
        </h2>
      </b-col>
      <b-col
        cols="12"
        class="mt-3"
      >
        <h4 style="text-align:start">
          {{ $t('activation_success.trader') }}
        </h4>
      </b-col>
      <b-col
        cols="12"
        class="mt-1 mb-2"
      >
        <h5 style="line-height:2; text-align:justify">
          {{ $t('activation_success.description') }}
        </h5>
      </b-col>
      <b-col
        cols="12"
        class=""
      >
        <h4
          class="text-secondary"
          style="text-align:start"
        >
          {{ $t('activation_success.thanks') }}
        </h4>
      </b-col>
      <b-col
        cols="12"
        class="mt-2 mb-1 px-0"
      >
        <b-button
          variant="success"
          :to="{name:'dashboard'}"
          style="font-size : 20px"
        >
          {{ $t('activation_success.cabin_btn') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {
  BRow,
  BCol,
  BCard,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
  },
}
</script>

  <style scoped>
  .success-register{
    max-width: 500px;
    width: 90%;
    margin: 3rem auto;
  }
  </style>
